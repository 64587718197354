<template>
  <PageListLayout
    icon="mdi-home"
    title="CÔNG TY NHẬP KHẨU"
    subTitle="Danh sách Công ty nhập khẩu"
    useAdd
    titleAdd="Thêm Công ty nhập khẩu"
    @add="createItem"
    @reset-filter="resetFilter"
  >
    <template slot="filter">
      <v-text-field
        append-icon="mdi-magnify"
        v-model="search"
        label="Tìm kiếm"
        clearable
        single-line
        outlined
        dense
        hide-details
      ></v-text-field>
    </template>
    <CustomTable
      :headers="headers"
      :items="tableData"
      :page.sync="page"
      :items-per-page="itemsPerPage"
      hide-default-footer
      :loading="loading"
      loading-text="Đang tải dữ liệu ..."
      :pageCount="pageCount"
      @change-page="changePage"
      classPaging="pt-2"
    >
      <template v-slot:[`item.action`]="{ item }">
        <v-menu>
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon v-bind="attrs" v-on="on">
              <v-icon color="textColor">mdi-dots-vertical</v-icon>
            </v-btn>
          </template>
          <v-list>
            <!-- <v-list-item @click="print(item)">
                <v-list-item-title>
                  <v-icon class="mr-2"> mdi-printer </v-icon>
                  In yêu cầu báo giá</v-list-item-title
                >
              </v-list-item> -->
            <v-list-item @click="goEdit(item)">
              <v-list-item-title>
                <v-icon class="mr-2"> mdi-pencil </v-icon>
                Cập nhật</v-list-item-title
              >
            </v-list-item>
            <v-list-item v-if="!item.is_static" @click="deleteItem(item)">
              <v-list-item-title>
                <v-icon class="mr-2"> mdi-delete </v-icon>
                Xóa công ty</v-list-item-title
              >
            </v-list-item>
          </v-list>
        </v-menu>
      </template>
    </CustomTable>
    <create-edit ref="form" @on-done="getAllList"></create-edit>
  </PageListLayout>
</template>
<script>
import { debounce } from "lodash";
import CreateEdit from "./create";
import { getCompanyManagers, deleteCompanyManagers } from "@/api/congty";
import { TYPE } from '@/constants/congtynhapkhau'
export default {
  components: { CreateEdit },
  data() {
    return {
      page: 1,
      pageCount: 1,
      itemsPerPage: 10,
      tableData: [],
      customsAreaList: [],
      btnLoading: false,
      menu: {},
      loading: false,
      search: "",
      headers: [
        { text: "STT", value: "stt" },
        { text: "Viết tắt", value: "code" },
        { text: "Tên công ty", value: "name" },
        { text: "Mã số thuế", value: "tax", align: "center" },
        { text: "Khu vực", value: "khu_vuc_hai_quan.name" },
        {
          text: "Hành động",
          value: "action",
          sortable: false,
          align: "center",
          width: "150",
        },
      ],
    };
  },
  computed: {},
  created() {
    this.getAllList()
  },
  watch: {
    search: debounce(async function (val) {
      await this.getAllList({
        search: val,
      });
    }, 300),
  },
  methods: {
    resetFilter() {
      this.formSeach = this.$options.data.call(this).formSeach;
    },
    async getAllCustomsAreaList() {},
    async getAllList(params = {}) {
      this.loading = true;
      try {
        const res = await getCompanyManagers({
          type: TYPE,
          page: this.page,
          perPage: this.itemsPerPage,
          ...params,
        });
        this.tableData = res.data.map((element, index) => ({
          stt: index + 1,
          ...element
        }));
        this.pageCount = res.last_page;
      } finally {
        this.loading = false;
      }
    },
    changePage(val) {
      this.page = val;
      this.getAllList();
    },
    editItem(item) {
      this.$refs.form.showFormEdit(item);
    },
    async deleteItem(item) {
      this.$confirmBox.show({
        title: "Xóa công ty",
        width: 500,
        body: "Bạn có chắc chắn muốn xóa công ty",
        action: async () => await deleteCompanyManagers(item),
        onDone: () => this.getAllList(),
      });
    },
    getSourceName(list, id) {
      return list.find((item) => item.value === id)
        ? list.find((item) => item.value === id).name
        : null;
    },
    createItem() {
      this.$refs.form.showFormAdd();
    },
    goEdit(item) {
      this.$refs.form.showFormEdit(item);
    },
    formatnumber(number) {
      return number.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.");
    },
    formatDate(date) {
      return new Date(date).toLocaleDateString();
    },
    getBackgroundOutFlow(date) {
      const today = new Date();
      const flowOutDate = new Date(date);

      const difference = Math.abs(today - flowOutDate);
      let days;
      days = difference / (1000 * 3600 * 24);

      if (days < 5) {
        return "#ffffff";
      } else if (days >= 5 && days <= 10) {
        return "#ffdf9a";
      } else if (days > 10 && days <= 15) {
        return "red";
      } else if (days > 15) {
        return "#fffdf3";
      }
    },
    getBackgroundInFlow(date) {
      const today = new Date();
      const flowInDate = new Date(date);

      const difference = Math.abs(today - flowInDate);
      let days;
      days = difference / (1000 * 3600 * 24);

      if (days < 5) {
        return "#ffffff";
      } else if (days >= 5 && days <= 10) {
        return "#ffdf9a";
      } else if (days > 10 && days <= 15) {
        return "red";
      } else if (days > 15) {
        return "#fffdf3";
      }
    },
  },
};
</script>
