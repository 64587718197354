<template>
  <v-dialog v-model="show" persistent width="600">
    <v-card>
      <v-card-title class="headline">{{
        edit ? "CẬP NHẬT CÔNG TY NHẬP KHẨU" : "THÊM CÔNG TY NHẬP KHẨU"
      }}</v-card-title>
      <br />
      <v-card-text>
        <v-form ref="form">
          <v-row>
            <v-col cols="6">
              <div class="label-form">Tên công ty</div>
              <v-text-field
                v-model="form.name"
                placeholder="Tên công ty"
                outlined
                dense
                prepend-inner-icon="mdi-code-tags"
                :rules="required"
              ></v-text-field>
            </v-col>
            <v-col cols="6">
              <div class="label-form">Viết tắt</div>
              <v-text-field
                v-model="form.code"
                placeholder="Viết tắt"
                outlined
                dense
                prepend-inner-icon="mdi-rename-box"
                :rules="required"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6">
              <div class="label-form">Mã số thuế</div>
              <v-text-field
                v-model="form.tax"
                placeholder="Mã số thuế"
                outlined
                dense
                prepend-inner-icon="mdi-code-tags"
                :rules="required"
              ></v-text-field>
            </v-col>
            <v-col cols="6">
              <div class="label-form">Khu vực</div>
              <v-autocomplete
                v-model="form.khu_vuc"
                :items="customArea"
                placeholder="Khu vực"
                :rules="required"
                item-text="name"
                item-value="id"
                dense
                outlined
                :return-object="false"
              ></v-autocomplete>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <div class="label-form">Ghi chú</div>
              <v-textarea
                v-model="form.note"
                placeholder="Ghi chú"
                outlined
                dense
                prepend-inner-icon="mdi-note"
                no-resize
              ></v-textarea>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text @click="show = false" class="mr-4">Đóng</v-btn>
        <v-btn
          :loading="btnLoading"
          color="primary"
          @click="addItem"
          v-if="!edit"
        >
          <v-icon left>mdi-plus</v-icon>
          Thêm mới
        </v-btn>
        <v-btn :loading="btnLoading" color="primary" @click="editItem" v-else>
          <v-icon left>mdi-pencil</v-icon>
          Cập nhật
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { addCompanyManagers, editCompanyManagers } from "@/api/congty";
import { getAllCustomsArea } from "@/api/khuvuchaiquan";
import { TYPE } from '@/constants/congtynhapkhau'
export default {
  data: () => ({
    show: false,
    edit: false,
    btnLoading: false,
    form: {
      type: TYPE,
    },
    nameRules: [(v) => !!v || "Tên không thể bỏ trống"],
    required: [(v) => !!v || "Không thể bỏ trống"],
    codeRules: [(v) => !!v || "Mã không thể bỏ trống"],
    customArea: [],
  }),
  computed: {},
  watch: {
    show(val) {
      if (!val) {
        this.form = this.$options.data.call(this).form
      }
    },
  },
  created() {
    this.fetchCustomArea();
  },
  methods: {
    async fetchCustomArea() {
      const res = await getAllCustomsArea();
      this.customArea = res;
    },
    showFormAdd() {
      this.show = true;
      this.edit = false;
      this.form = { ...this.form };
    },
    async showFormEdit(data) {
      this.edit = true;
      this.show = true;
      this.form = { ...this.form, ...data };
    },
    async addItem() {
      this.$refs.form.validate();
      if (this.$refs.form.validate()) {
        this.btnLoading = true;
        try {
          await addCompanyManagers(this.form);
          this.$emit("on-done");
          this.$toast.info("Thêm thành công", {
            position: "top-center",
            timeout: 2000,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 0.6,
            showCloseButtonOnHover: false,
            hideProgressBar: true,
            closeButton: "button",
            icon: true,
          });
        } finally {
          this.btnLoading = false;
          this.show = false;
        }
      }
    },
    async editItem() {
      this.$refs.form.validate();
      if (this.$refs.form.validate()) {
        this.btnLoading = true;
        try {
          this.show = false;
          this.btnLoading = false;
          await editCompanyManagers(this.form);
          this.$emit("on-done");
          this.$toast.info("Cập nhật thành công", {
            position: "top-center",
            timeout: 2000,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 0.6,
            showCloseButtonOnHover: false,
            hideProgressBar: true,
            closeButton: "button",
            icon: true,
          });
        } catch (error) {
          this.btnLoading = false;
          this.show = false;
        }
      }
    },
  },
};
</script>
